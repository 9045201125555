.home {
  display: flex;
  justify-content: center;
  height: 90vh;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}

.home-resume {
  align-items: flex-start;
}

.home-code {
  align-items: flex-end;
}

.home-tittle {
  font-size: 32px;
  color: #ff79c6;
  font-weight: bold;
}

.home-sub-tittle {
  font-size: 24px;
  color: #8be9fd;
  padding-bottom: 20px;
  font-weight: bold;
}

.home-p {
  font-size: 17px;
  color: #f1fa8c;
  font-weight: bold;
}

.social-links {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  list-style: none;
  padding: 10px 0;
}

.img-links {
  width: 35px;
}

pre {
  background-color: #282a36;
  color: #f8f8f2;
  padding: 40px 40px 40px 5px;
  border-radius: 10px;
  font-family: "Courier New", Courier, monospace;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  overflow-x: auto;
  margin: 0;
}

code {
  font-size: 0.8rem;
  line-height: 1.2;
  white-space: pre-wrap;
}

code .string {
  color: #f1fa8c;
}

code .keyword {
  color: #ff79c6;
}

code .property {
  color: #8be9fd;
}

@media (max-width: 575px) {
  .home {
    align-items: center;
    height: 40vh;
    width: 90%;
  }

  .home-tittle {
    font-size: 27px;
    color: #ff79c6;
  }
  
  .home-sub-tittle {
    font-size: 20px;
    padding-bottom: 20px;
  }
  
  .home-p {
    font-size: 14px;
  }

  .img-links {
    width: 28px;
  }

  pre {
    padding: 10px 20px 10px 5px;
  }
}
