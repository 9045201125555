.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.img-dev {
  width: 50px;
}

@media (max-width: 575px) {
  .img-dev {
    display: none;
  }
}