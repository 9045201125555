* {
  font-family: "Courier New", Courier, monospace;
  padding: 0;
  margin: 0;
}

.container-fluid {
  background-color: #1c1c1c;
  color: #fff;
}

.proj-tittle, .skills-tittle, .ab-tittle {
  font-size: 40px;
  font-weight: bold;
  color: #f1fa8c;
}







/*
.row-header, .row-home, .row-skills, .row-projects, .row-about, .rowfooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
*/