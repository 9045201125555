.header {
  display: flex;
  align-items: center;
  height: 10vh;
}

.header-logo {
  justify-content: flex-start;
  margin-left: 50px;
}

.header-menu {
  justify-content: flex-end;
  margin-right: 50px;
}

.ul-header {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 30px;
}

.links-nav {
  text-decoration: none;
  color: #f1fa8c;
  font-size: 20px;
  font-weight: bold;
}

.links-nav:hover {
  color: #8be9fd;
}

.img-header {
  width: 50px;
}

#menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 2rem;
  color: #f1fa8c;
  cursor: pointer;
}

.hamburger-icon {
  font-size: 2rem;
}

.menu {
  display: block;
}

@media (max-width: 576px) {
  .header {
    margin: 0;
  }

  .ul-header {
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 0;
    gap: 20px;
    background-color: #1c1c1c;
    width: 100%;
    padding: 10px;
    top: 10vh;
    z-index: 999;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }

  .menu.open .ul-header {
    max-height: 300px;
    opacity: 1;
  }

  #menu-button {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 30px;
    height: 30px;
  }
  
  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 24px;
    transition: transform 0.3s ease;
  }
  
  .hamburger-icon span {
    background-color: #f1fa8c;
    height: 4px;
    width: 100%;
    border-radius: 2px;
    transition: all 0.3s ease;
    position: relative;
  }
  
  .hamburger-icon span:nth-child(1) {
    transform-origin: top left;
  }
  
  .hamburger-icon span:nth-child(3) {
    transform-origin: bottom left;
  }
  
  .hamburger-icon.open span:nth-child(1) {
    transform: 0% 0%;
    transform: rotate(45deg) scaleX(1);
  }
  
  .hamburger-icon.open span:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger-icon.open span:nth-child(3) {
    transform: 0% 100%;
    transform: rotate(-45deg) scaleX(1);
  }
}