.about {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}

.about-tittle {
  height: 20vh;
}

.div-about {
  height: 60vh;
}

.frase-about {
  font-size: 20px;
  color: #8be9fd;
}

@media (max-width: 575px) {
  .frase-about {
    font-size: 12px;
  }
}