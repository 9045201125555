.projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
}

.projects-tittle {
  height: 20vh;
}

.projects-grid {
  height: 80%;
  flex-wrap: wrap;
}

.proj-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.li-projects {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(30%);
  background-color: #282a36;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  gap: 10px;
  transition: transform 0.3s ease;
}

.li-projects:hover {
  transform: scale(1.05);
  background-color: #44475a;
  cursor: pointer;
}

.projects-name {
  font-size: 22px;
  color: #8be9fd;
}

.img-projects {
  width: 120px;
  height: auto;
}

.description {
  font-size: 12px;
  color: #f1fa8c;
}

@media (max-width: 575px) {
  .li-projects {
    width: 100%;
  }

  .proj-grid {
    padding: 0;
  }

  .projects-name {
    font-size: 18px;
    color: #8be9fd;
  }
  
  .img-projects {
    width: 100px;
    height: auto;
  }
  
  .description {
    font-size: 12px;
    color: #f1fa8c;
  }
}
